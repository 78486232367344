.bloghero {
    background-image: linear-gradient(#ffffff00, #ffffff00), url(../assets/home/blog\ page\ yog\ yatra.jpg);
    height: 70vh;
}

.bloghero h1 {
    font-size: 5rem;
    font-weight: 700;
    margin-bottom: 50px;
  }

  .readmore {
    background-color: #3d181870;
    color: #ffffff3f;
  }

  .themebg {
    background-color: #f28c86;
  }

  .darktext {
    color: #3d1818;
  }