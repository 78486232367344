.footer {
  gap: 40px;
  display: flex;
  padding: 2% 4%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #F8F8F6;
}

.map {
  width: 100%;
  height: 300px;
  border: 0;
  padding: 10px 10px;
}

.map-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
}

.footer-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.footer-logo {
  background-image: url(../assets/yogyatra.co.in.svg);
  width: 170px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;
}

.link-container {
  padding: 10px 10px;
}

.link-container {
  margin: 20px 0px;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.link {
  font-weight: 600;
  color: #3d1617;
  text-decoration: none;
  text-align: start;
}

.container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.social-container {
  display: flex;
  width: 100%;
  height: auto;
  align-items: end;
  justify-content: center;
  flex-direction: column;
}

link {
  text-align: start;
}

.social-container h1 {
  font-weight: 800;
  font-size: 20px;
}


.facebook {
  background-image: url(../assets/home/icons8-facebook-50.png);
  height: 50px;
  width: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.instagram {
  background-image: url(../assets/home/icons8-instagram-50.png);
  height: 100%;
  width: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.youtube {
  background-image: url(../assets/home/icons8-youtube-50.png);
  height: 100%;
  width: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.footer-foot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

@media (max-width: 800px) {

  .footer {
    flex-direction: column;
  }

  .link-container {
    padding: 10px 10px;
    margin: 20px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.social-container {
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-foot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.footer-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
}