h1 {
    color: #3D1818;
    font-weight: 600;
    font-size: 34px;
}

h2 {
    color: #444;
    font-weight: 600;
    font-size: 30px;
    padding: 27px 5px;
}

h3 {
    color: #444;
    font-weight: 600;
    font-size: 20px;
    padding: 20px 0px;
}

h3::before{
    content: "🔵";
    font-size: 12px;
    padding: 0 5px;
}