.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #ccc;
    width: 40%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

.close-btn {
    background: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    padding: 0;
}

.close-btn img {
    width: 20px;
    height: 20px;
}

.modal-content form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.modal-content form .labelinputcontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal-content form label {
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
}

.modal-content form input,
.modal-content form select {
    width: 100%;
    padding: 10px 5px;
    border: 0;
    border-bottom: 1px solid #f5928bb9;
    font-size: 16px;
    margin-top: 5px;
    transition: border-color 0.3s ease;
}

.modal-content form input:focus,
.modal-content form select:focus {
    outline: none;
    border-bottom: 1px solid #007BFF;
}

.submit-btn {
    width: 30%;
    margin: 20px 5px;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #F5928B;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 auto;
}

.modalheading {
    color: #F5928B !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    font-size: 30px;
}

.submit-btn:hover {
    background-color: #F5928B;
}

.modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}