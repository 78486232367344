.head {
  height: 70vh;
  background-image: linear-gradient(#ffffff00, #ffffff00), url(../assets/home/yogyatraaboutis.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.head h1 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 50px;
}

.heading {
  color: #3d1818;
}

.bordercolor {
  border-color: #f5928bb1;
}

.background {
  background-image: url(../assets/home/layer.png);
}

.backgroundyogabase {
  height: 350px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.backgroundyoga1 {
  background-image: url(../assets/home/yogapose1.png);
}

.backgroundyoga2 {
  background-image: url(../assets/home/yogapose2.png);
}

.courses {
  background-image: url(../assets/home/personalizedlearing.svg);
}

.trainers {
  background-image: url(../assets/home/content.svg);
}

.learning {
  background-image: url(../assets/home/trainer.svg);
}