@tailwind base;
@tailwind components;
@tailwind utilities;

.font {
  font-family: sourcesans;
  src: url(./Font/source-sans-pro.regular.ttf);
}

.philosopher {
  font-family: philosopher;
  src: url(./Font/Philosopher-Regular.ttf);
}

body {
  margin: 0;
  font-family: sourcesans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  color: #3d1818;
}

h1 {
  color: #3d1818;
}

.mainheading {
  font-family: philosopher;
}

h2 {
  color: #3d1818;
}