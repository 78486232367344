.contact-main-container {
  background-image: linear-gradient(#fff, #ffffff7c, #f8f8f6), url(../assets/home/contact.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.contact-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  margin: 30px 0;
  background-image: linear-gradient(#f5928bc1, #f5928bc1, #f5928bc1);
  border-radius: 10px;
}

.contact-form-sidepanel {
  display: flex;
  width: 100%;
}

.contact-img-container {
  background-repeat: no-repeat;
  background-position: center;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: end;
}

.contact-img-container h1 {
  font-weight: 800;
  color: #fff;
  width: 70%;
  line-height: 70px;
  margin-bottom: 100px;
}

.contact-form-sidepanel h3 {
  color: #fff;
  font-weight: 600;
}

.contact-form-sidepanel {
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 30px;
  flex-direction: column;
}

.talk-second-container h3::before {
  content: "" !important;
  padding: 0;
}

.talk-first-container h2 {
  color: #fff;
  padding: 0;
}

.talk-first-container {
  display: flex;
  flex-direction: column;
}

.talk-second-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin-top: 20px;
  gap: 5px;
}

.contact-form-sidepanel h1 {
  font-size: 3rem;
  color: #fff;
}

.contact-form-sidepanel h2 {
  font-weight: 500;
  font-size: 20px;
}

.basicontact {
  display: flex;
  gap: 7px;
  justify-content: start;
  align-items: center;
}

.contact-talk {
  color: #fff;
  font-size: large;
}

.socialicons {
  color: #f5928b !important;
}

.contact-form-container {
  width: 100%;
  margin: 40px auto;
  padding: 30px 40px;
  border-radius: 8px;
}

.contact-input {
  background-color: transparent;
  appearance: none;
  border: 0;
  border-color: transparent;
}

.contact-form-container h2 {
  text-align: left;
  color: #fff;
  padding-bottom: 20px;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 5px;
  border-bottom: 2px solid #fff;
  margin-bottom: 15px;
}

.contact-form textarea {
  resize: vertical;
  margin: 0 auto;
}

.underinput {
  height: 2px;
  width: 95%;
  background-color: #fff;
  margin: 0 auto;
}

.error {
  color: red;
  font-size: 12px;
}

.submit-button {
  width: 30%;
  display: block;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #f5928b;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #fff;
  box-shadow: 1px 1px 1px #f5928b;
  transition: all ease .3s;
}

.social-link-container {
  width: 100%;
  display: flex;
  gap: 7px;
  justify-content: end;
  align-items: center;
}


/* responsive */

@media (max-width: 700px) {
  .contact-sub-container {
    flex-direction: column;
  }

  .contact-container {
    width: 100%;
    padding: 20px 5px;
  }

  .social-link-container {
    justify-content: center;
    align-items: center;
  }

  .submit-button {
    width: 50%;
  }

  .contact-form-container {
    padding: 0 auto;
  }
}

@media (max-width: 800px) {
  .contact-container {
    width: 100%;
  }
}

/* Responsive end */