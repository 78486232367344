body {
  overflow-x: hidden;
}


/* header1 */
.header {
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(../assets/home/Group\ 2.png);
  /* Full viewport height */
  overflow: hidden;
}

@media (max-width: 700px) {
  .header {
    height: 65vh;
  }
}

@media (max-width: 376) {
  .header {
    height: 90vh;
  }
}

.darktext {
  color: #3D1818 !important;
}

.cremebg {
  background-color: #f8f8f6;
}

.button-dark {
  background-color: #3D1818;
  color: #fff;
  font-size: 14px;
}

/* Default image style (desktop) */
.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the entire section without distortion */
}

/* Text content styling */
/* Responsive grid layout for the videos */
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.video-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.video-card:hover {
  transform: scale(1.05);
  /* Optional hover effect */
}

.video-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.slick-next {
  right: 0 !important;
  margin-right: 0 !important;
}

.video-info {
  padding: 15px;
  text-align: left;
}

.video-info h3 {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #3D1818;
}

.video-info p {
  font-size: 14px;
  color: #555547;
  margin: 5px 0;
}



@media (max-width: 768px) {

  .video-section {

    padding: 50px 5%;
  }




}



/* video end */



/* live classes */

.live-classes-section {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 5%;

}

.live-classes-content {
  max-width: 80%;

}

.live-classes-content h2 {
  margin-top: 80px;
  text-align: center;
  align-items: center;
  font-size: 32px;
  color: #3D1818;
  margin-bottom: 10px;
}

.live-classes-content p {
  text-align: center;
  align-items: center;
  font-size: 18px;
  color: #555547;
}



.banner1 {
 background-image: url(../assets/home/community.png);
 background-position: center;
 background-repeat: no-repeat;
 background-size: contain;
 width: 80%;
 display: flex;
 margin: 0 auto;
 height: 300px;
}

@media (max-width: 800px) {
  .banner1 {
    background-image: url(../assets/home/Group\ 4.png);
    width: 100%;
    flex-direction: column;
    background-position: center;
    background-size: cover;
  }

}

.submit-button-community {
  width: 30%;
  display: block;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #3D1818;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}


banner1 img {
  width: 100%; /* Make image responsive */
  height: auto; /* Keep aspect ratio */
}

.overlay-content {
  position: absolute;
  top: 50%; /* Center vertically */
  right: 15%; /* Align text and button to the right */
  transform: translateY(-50%); /* Adjust to vertically center the text */
  color: white;
  text-align: right; /* Align text to the right */
  align-items: center;
  justify-content: center;
}

.overlay-content h2 {
  font-size: 36px;
  margin-bottom: 15px;
  margin-left: 40px;
  color: #3D1818;
}

.header5 h2 {
  text-align: center !important;
  color: #3D1818;
  padding: 10px 10px 0 0 ;
}

.enroll-btn {
  padding: 10px 20px;
  background-color: #f28c86;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enroll-btn:hover {
  background-color: #5c2620;
}

/* live classes end */


/* manager */
.teachers-section {
  text-align: center;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 5%;
  margin-top: 5%;
  padding-bottom: 5%;
  justify-content: center;
  align-items: center;
  background-color: #F8F8F6;
}


.section-description {
  font-size: 18px;
  font-weight: 400;
  color: #4f4f4f;
  margin-bottom: 40px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.teachers-container {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.teacher {
  text-align: center;
}

.teacher-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.teacher h3 {
  font-size: 20px;
  color: #3D1818;
  margin-bottom: 8px;
}

.teacher p {
  font-size: 18px;
  font-weight: 400;
  color: #4f4f4f;
  max-width: 220px;
  margin: 0 auto;
}


@media (max-width: 768px) {

  .live-classes-section {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-left: 0%;
    padding-right: 0%;
  
  }

  .teachers-container {
    flex-direction: column;
    justify-content: center;
    gap: 60px;
  }

  .teacher-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 0px;
  }


  .teacher p {
    font-size: 18px;
    font-weight: 400;
    color: #4f4f4f;
    max-width: 100%;
    margin: 0 auto;
  }



}


/* manager end */


/* slider */

.sliding{
  padding-top: 5%;
}

.slick-slide img {
  display: block;
  margin: auto;
  max-width: 100%;
  height: auto;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #5c2620;
}

.slick-prev,
.slick-next {
  color: #5c2620;
  z-index: 1;
}

.video-slide{
  border: 0;}
  .video-slide iframe {
    border-radius: 10px; /* Adjust this value to your desired roundness */
    overflow: hidden; /* Ensures that content inside respects the border-radius */
  }
/* slider end */


/* blogs */



.blogs {
  text-align: center;
  padding-top: 5%;
}

.header {
  margin-bottom: 20px;
}
.header5 p{
  padding-left: 14%;
  padding-right: 14%;

}

.discount-btn {
  background-color: #ff867b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
}

h2 {
  color: #3D1818;
  font-size: 32px;
  font-weight: 700;
  margin-top: 10px;
}

p {
  color: #555547;
  font-size: 18px;
  margin-top: 10px;
  font-weight: 400;
}

.blog-items {
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
}

.blog-card {
  background-color: #ff867b;
  color: white;
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  gap: 27px;
  padding: 0px;
  text-align: center;
}

.blog-card img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.blog-card h3 {
  font-size: 15px;
  color: #fff;
  margin: 15px 10px;
}

.blog-card a {
  display: inline-block;
  color: white;
  font-size: 13px;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 5px;
}

.blog-card a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .blog-items {
    flex-direction: column;
    padding-left: 0%;
    padding-right: 0%;
    align-items: center;
    max-width: 100%;
  }

}


/* blogs end */

.banner2 {
  margin: 0;
  /* Ensure no margins */
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner2 img {

  max-width: 80%;
}



/* Desktop reviews */

.reviews {
  padding: 5% 10%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

/* Individual review box */
.review-box {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f7f2ec;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Mobile reviews section */
.mobile-reviews {
  display: none;
  /* Hidden by default for desktop */
  padding: 10%;
  background-color: #f8f8f6;
}

/* Mobile review box */
.mobile-review-box {
  background-color: #f7f2ec;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Media query to hide/show sections based on screen size */
@media (max-width: 768px) {
  .reviews {
    display: none;
    /* Hide desktop reviews on mobile */
  }

  .mobile-reviews {
    display: block;
    /* Show mobile reviews on mobile */
  }

  .stars {
    display: flex;
    /* Align stars in a row */
    justify-content: flex-start;
    margin-bottom: 8px;
    /* Space between stars and title */
  }

  .stars img {
    margin: 3px;
    margin-right: 0px;
    /* Space between stars */
    width: 20px;
    /* Adjust star size if needed */
    height: auto;
    /* Maintain aspect ratio */
  }


}

@media (min-width: 769px) {
  .mobile-reviews {
    display: none;
    /* Hide mobile reviews on desktop */
  }
}

.bordercreme {
  border-color: #f8f8f6;
}



/* reviews end */

/* FAQ */
.faq-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0% 10%;
  padding-bottom: 3%;
  flex-direction: column;
}

.faq-section h1 {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 700;
  color: #3D1818;
}

.expandable {
  width: 100%;
  padding: 2px 5px;
  transition: all .5s ease;
}


.question{
  font-size: 16px;
  font-weight: 500;
}
  
  /* faq end */


  /* INstructor */

  .instructor-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
  }

  .instructor {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .video {
  border-radius: 20px;
    height: 50vh;
    width: 100vh;
  }

  .instructor-box {
    width: 90%;
    background-color: #f3eee9;
    height: 315px;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url(../assets/home/yogapose.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .dp {
   
    height: 180px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .nameplate {
    border-radius: 0;
    height: 25px;
    width: 150px;
    position: absolute;
    top: 80%;
    left: 67%;
    background-color: #f28c86;
  }

  /* instructor end */